import { IEnvConfig } from '..';

const config: IEnvConfig = {
  name: "QA",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://app-dev.moverse.biz/api/dev"
    }
  }
}

export default config;
