import { } from "~/dto";
import { LoginReq, RegisterReq, UserSessionDto } from "~/dto/auth.dto";
import { EventDto, IRewardEventReq } from "~/dto/events.dto";
import { rootApiService } from "./@common";

const Enpoint = {
    create: "/api/admin/events/create".trim(),
    update: "/api/admin/events/update".trim(),
    detail: "/api/admin/events/detail".trim(),
    getListEvent: "/api/admin/events/list".trim(),
    fakerUserEvent: "/api/admin/tools/fakerUserEvent".trim(),
    downloadExcel: "/api/admin/events/downloadExcel".trim(),
    rewardTokenByEvent: "/api/admin/events/rewardTokenByEvent".trim(),
}

export class AdminEventService {

    async getListEvent(params: { pageIndex: number, pageSize: number }) {
        return rootApiService.get<{ data: EventDto[], total: number }>(Enpoint.getListEvent, params);
    }

    async detail(params: { id: string }) {
        return rootApiService.get<EventDto>(Enpoint.detail, params);
    }
    async create(body: Partial<EventDto>) {
        return rootApiService.post<EventDto>(Enpoint.create, body);
    }

    async update(body: { id: string } & Partial<EventDto>) {
        return rootApiService.post<EventDto>(Enpoint.update, body);
    }


    async buffEvent(body: {
        numberOfUsers: number
        eventId: string
        minDistance: number
        maxDistance: number
        minDuration: number
        maxDuration: number
        minStartedTime: string
        maxStartedTime: string
    }) {
        const res = await rootApiService.fetch({
            url: Enpoint.fakerUserEvent,
            data: body,
            responseType: "blob",
            method: "POST"
        });

        return new Blob([res.data], {
            type: res.headers['content-type'],
        })
    }

    async dowloadScoredBoard(params: { eventId: string }) {
        const res = await rootApiService.fetch({
            url: Enpoint.downloadExcel,
            params,
            responseType: "blob",
            method: "GET"
        });

        return new Blob([res.data], {
            type: res.headers['content-type'],
        })
    }

    async rewardTokenByEvent(body: IRewardEventReq) {
        return rootApiService.post(Enpoint.rewardTokenByEvent, body);
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdminEventService();
