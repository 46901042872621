
import { DashboardOutlined, EditOutlined, FieldNumberOutlined, HomeOutlined, HomeTwoTone, PlayCircleOutlined, PlusSquareOutlined, StarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { v4 } from 'uuid';
import { IRouter } from '~/routers';
import DashboardView from './DashboardView';
import CreateEventView from './events/CreateEventView';
import EditEventView from './events/EditEventView';
import ListEventView from './events/ListEventView';
import BuffEventView from './events/BuffEventView';
import RewardEventView from './events/RewardEventView';
export const mainRouter: IRouter[] = [
  {
    path: "",
    key: v4(),
    element: <DashboardView />,
    title: "Dashboard",
    isMenu: true,
    icon: <DashboardOutlined />,
  },
  {
    path: "events",
    key: v4(),
    element: <Outlet />,
    title: "Events",
    icon: <StarOutlined />,
    isMenu: true,
    children: [
      {
        path: "",
        key: v4(),
        element: <ListEventView />,
        icon: <UnorderedListOutlined />,
        title: "List event",
        isMenu: true,
      },

      {
        path: "create",
        key: v4(),
        element: <CreateEventView />,
        icon: <PlusSquareOutlined />,
        title: "Create Event",
        isMenu: true,
      },
      {
        path: "edit",
        key: v4(),
        element: <EditEventView />,
        icon: <EditOutlined />,
        title: "Edit Event",
        isMenu: false,
      },
      {
        path: "buff",
        key: v4(),
        element: <BuffEventView />,
        icon: <FieldNumberOutlined />,
        title: "Buff Event",
        isMenu: false,
      },
      {
        path: "reward",
        key: v4(),
        element: <RewardEventView />,
        icon: <PlayCircleOutlined />,
        title: "Reward Event",
        isMenu: false,
      },
    ]
  },

]
