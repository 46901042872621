import React from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, Spin } from 'antd';
import { useAuthStore } from '~/stores/authStore';





const LoginView: React.FC = () => {

  const { login, isLoading } = useAuthStore();
  const onFinish = (values: any) => {
    console.log('Success:', values);
    login(values)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Layout style={{ height: '100vh', justifyContent: 'center', alignItems: "center" }} >
      <Spin tip="Loading..." spinning={isLoading}  >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>

    </Layout>

  )
}

export default LoginView;
