import { Button, Col, DatePicker, Descriptions, Form, InputNumber, Row, Spin, theme, Image, Upload, Divider } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import * as XLSX from "xlsx";
import { useSearchParams } from 'react-router-dom';
import { toastService } from '~/services';
import adminEventService from '~/services/admin-event.service';
import { ApiException } from '~/@core/dto';
import { DownCircleFilled, DownloadOutlined, SaveOutlined, UpCircleOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import TableControl from '~/@ui/TableControl';
import BaseView from '~/components/BaseView';
import { ColumnsType } from 'antd/es/table';



const RewardEventView: React.FC = () => {
  const { token: { colorBgBase, colorBgTextActive } } = theme.useToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [dataTable, setDataTable] = useState([])

  const [eventData, setEventData] = useState({
    name: "",
    banner: ""
  })
  const id = searchParams.get("id");

  const loadData = useCallback(async () => {
    try {
      if (!id) {
        throw new ApiException("Empty id")
      }
      const item = await adminEventService.detail({ id });
      const { banner, startTime, endTime, desc, name, ...resItem } = item;
      setEventData({
        name,
        banner
      })
    } catch (error) {

    }
  }, [id])

  useEffect(() => {
    loadData();
  }, [form, id, loadData])



  const loadingFile = useCallback((file: RcFile, FileList: RcFile[]) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      try {
        setDataTable([]);
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const lines: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const headerKeys: string[] = lines[0];
        const lineDatas = lines.slice(1);
        const data = lineDatas.map(items => {
          const itemReust = {};
          for (let i = 0; i < headerKeys.length; i++) {
            Object.assign(itemReust, {
              [headerKeys[i]]: items[i]
            })
          }
          return itemReust;
        })
        console.log(`-------------------`);
        console.log({
          headerKeys,
          data
        });
        console.log(`-------------------`);
        setDataTable(data)


      } catch (e) {
        console.log(`-------------------`);
        console.log(e);
        console.log(`-------------------`);
      }
    };
    reader.readAsBinaryString(file);
    return false;
  }, []);

  const columns: ColumnsType<{ email: string; amount: number; lockAmount: number }> = [
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
    },
    {
      title: 'lockAmount',
      dataIndex: 'lockAmount',
      key: 'lockAmount',
      ellipsis: true,
    },
  ]


  const onUpdateReward = useCallback(async () => {

    try {
      try {
        setIsLoading(true);
        await adminEventService.rewardTokenByEvent({
          eventId: id,
          userRewards: dataTable
        });
        setDataTable([]);
        toastService.success();
      } catch (error) {
        toastService.handleError(error);
      }
    } catch (error) {

    }

    setIsLoading(false);
  }, [dataTable, id])


  const renderInfoPromotion = useCallback(() => {
    return (
      <Descriptions column={4} title="Promotion infomation" layout="vertical" bordered size='default' >
        <Descriptions.Item label="Name">{eventData.name}</Descriptions.Item>
        <Descriptions.Item label="Banner"><Image src={eventData.banner} height={100} /></Descriptions.Item>

        <Descriptions.Item label="Upload Excel Reward Template">

          <Upload
            multiple={false}
            showUploadList={false}
            beforeUpload={loadingFile}
          >
            <Button type="primary" icon={<UpCircleOutlined />}>Upload</Button>
          </Upload>
        </Descriptions.Item>
        <Descriptions.Item label="Update Reward Event">
          <Button disabled={dataTable.length <= 0}
            onClick={onUpdateReward}
            type="primary" icon={<SaveOutlined />}>Save</Button>
        </Descriptions.Item>
      </Descriptions>
    )
  }, [dataTable.length, eventData.banner, eventData.name, loadingFile, onUpdateReward])


  return (
    <BaseView>
      <div>
        {renderInfoPromotion()}
      </div>
      <Divider />
      <TableControl
        columns={columns}
        // dataSource={[]}
        // defaultPageSize={20}
        loadData={({ pageIndex, pageSize }) => {
          const start = (pageIndex - 1) * pageSize;
          const end = pageIndex * pageSize;
          return {
            data: dataTable.slice(start, end),
            total: dataTable.length
          }
        }}
      />
    </BaseView>

  );
};

export default RewardEventView;
