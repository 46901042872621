import { Button, Col, DatePicker, Descriptions, Form, InputNumber, Row, Spin, theme, Image } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toastService } from '~/services';
import adminEventService from '~/services/admin-event.service';
import { ApiException } from '~/@core/dto';
import { DownCircleFilled, DownloadOutlined } from '@ant-design/icons';



const BuffEventView: React.FC = () => {
  const { token: { colorBgBase, colorBgTextActive } } = theme.useToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);


  const [eventData, setEventData] = useState({
    name: "",
    banner: ""
  })
  const id = searchParams.get("id");

  const loadData = useCallback(async () => {
    try {
      if (!id) {
        throw new ApiException("Empty id")
      }
      const item = await adminEventService.detail({ id });
      const { banner, startTime, endTime, desc, name, ...resItem } = item;
      setEventData({
        name,
        banner
      })
    } catch (error) {

    }
  }, [id])

  useEffect(() => {
    loadData();
  }, [form, id, loadData])




  const onFinish = async (values: any) => {
    const { minStartedTime, maxStartedTime, ...restValues } = values;
    const body = {
      ...restValues,
      eventId: id,
      minStartedTime: new Date(minStartedTime).toISOString(),
      maxStartedTime: new Date(maxStartedTime).toISOString()
    }
    try {
      setIsLoading(true);
      const data = await adminEventService.buffEvent(body);
      // Create a URL for the blob object
      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'faker_user.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toastService.success();
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };


  const download = useCallback(async () => {

    try {
      setIsLoading(true);
      const data = await adminEventService.dowloadScoredBoard({
        eventId: id
      });
      // Create a URL for the blob object
      const url = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'scored_board.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toastService.success();
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  }, [id])


  const renderInfoPromotion = useCallback(() => {
    return (
      <Descriptions column={4} title="Promotion infomation" layout="vertical" bordered size='default' >
        <Descriptions.Item label="Name">{eventData.name}</Descriptions.Item>
        <Descriptions.Item label="Banner"><Image src={eventData.banner} height={100} /></Descriptions.Item>
        <Descriptions.Item label="Dowload ScoredBoard"><Button type="primary" icon={<DownloadOutlined />} onClick={download} > Download </Button></Descriptions.Item>
      </Descriptions>
    )
  }, [download, eventData.banner, eventData.name])


  return (
    <div style={{ width: '100%' }}>
      <Spin tip="Loading..." spinning={isLoading}  >
        <div>
          {renderInfoPromotion()}
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={[24, 2]}>
            <Col span={6}>
              <Form.Item
                label="Number Of Users"
                name="numberOfUsers"
                rules={[{ required: true, message: 'Please input your numberOfUsers!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>
            <Col span={18}>

            </Col>
            <Col span={6}>
              <Form.Item
                label="minDistance"
                name="minDistance"
                rules={[{ required: true, message: 'Please input your minDistance!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="maxDistance"
                name="maxDistance"
                rules={[{ required: true, message: 'Please input your maxDistance!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="minDuration"
                name="minDuration"
                rules={[{ required: true, message: 'Please input your minDuration!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="maxDuration"
                name="maxDuration"
                rules={[{ required: true, message: 'Please input your maxDuration!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>


            <Col span={6}>
              <Form.Item
                label="minStartedTime"
                name="minStartedTime"
                rules={[{ required: true, message: 'Please input your minStartedTime!' }]}
              >
                <DatePicker showTime={{ format: "hh:mm" }} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="maxStartedTime"
                name="maxStartedTime"
                rules={[{ required: true, message: 'Please input your maxStartedTime!' }]}
              >
                <DatePicker
                  showTime={{ format: "hh:mm" }}
                  style={{ width: '100%' }} />
              </Form.Item>
            </Col>

          </Row>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              Buff user
            </Button>
          </Form.Item>
        </Form>
      </Spin>

    </div>
  );
};

export default BuffEventView;
