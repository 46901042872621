import { } from "~/dto";
import { LoginReq, RegisterReq, UserSessionDto } from "~/dto/auth.dto";
import { rootApiService } from "./@common";

const Enpoint = {
  authenticate: "/api/admin/authenticate".trim(),
  login: "/api/admin/login".trim(),
  register: "/api/admin/register".trim(),
}

export class AuthService {
  async authenticate() {
    return rootApiService.get<UserSessionDto>(Enpoint.authenticate);
  }

  login(loginReq: LoginReq) {
    return rootApiService.post<UserSessionDto>(Enpoint.login, loginReq)
  }


}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
