import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Spin, theme, Upload } from 'antd';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EEventFormJoin, EEventMode, EEventType, ESessionSourceLink } from '~/common/enums';
import DefUpload from '~/components/DefUpload';
import { rootApiService, toastService, uploadApiService } from '~/services';
import adminEventService from '~/services/admin-event.service';

const { Option } = Select;



const CreateEventView: React.FC = () => {
  const { token: { colorBgBase, colorBgTextActive } } = theme.useToken();
  const [form] = Form.useForm();
  const [banner, setBanner] = useState("");


  const [isLoading, setIsLoading] = useState(false)



  const onFinish = async (values: any) => {
    const { startTime, endTime, ...restValues } = values;
    const body = {
      ...restValues,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString()
    }
    console.log(`-------------------`);
    console.log({ body });
    console.log(`-------------------`);

    try {
      setIsLoading(true);
      await adminEventService.create(body);
      await new Promise(resolve => {
        setTimeout(() => {
          resolve("Ok")
        }, 3 * 1000);
      })
      toastService.success();
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };

  const onReset = () => {
    form.resetFields();
  };



  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div style={{ width: '100%' }}>
      <Spin tip="Loading..." spinning={isLoading}  >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="startTime"
                name="startTime"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <DatePicker
                  showTime={{ format: "hh:mm" }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="endTime"
                name="endTime"
                hasFeedback
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <DatePicker
                  showTime={{ format: "hh:mm" }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Mode"
                name="mode"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your Mode!' }]}
              >
                <Select

                  placeholder="Source Links Select"
                  allowClear

                >
                  <Option value={EEventMode.Public}>{EEventMode.Public}</Option>
                  <Option value={EEventMode.Private}>{EEventMode.Private}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Type"
                name="type"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your Type!' }]}
              >
                <Select
                  placeholder="Type Select"
                  allowClear

                >
                  <Option value={EEventType.Challenge}>{EEventType.Challenge}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="SourceLinks"
                name="acceptSourceLinks"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your SourceLinks!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Source Links Select"
                  allowClear

                >
                  <Option value={ESessionSourceLink.MOVERSE}>{ESessionSourceLink.MOVERSE}</Option>
                  <Option value={ESessionSourceLink.STRAVA}>{ESessionSourceLink.STRAVA}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Form join"
                name="formJoin"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your formJoin!' }]}
              >
                <Select

                  placeholder="formJoin Select"
                  allowClear

                >
                  <Option value={EEventFormJoin.Personal}>{EEventFormJoin.Personal}</Option>
                  <Option value={EEventFormJoin.Team}>{EEventFormJoin.Team}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Organizer"
                name="organizer"
                rules={[{ required: true, message: 'Please input your organizer!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Bonus EMove"
                name="bonusEMove"
                rules={[{ required: true, message: 'Please input your bonusEMove!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                name="banner"
                label="Upload banner"
                getValueProps={(value) => ({ value })}
              >
                <DefUpload
                  name="banner"
                  value={banner}
                  accept="image/*"
                  onChange={(url) => setBanner(url)}
                  requestUpload={async (file) => {
                    const formData = new FormData();
                    formData.append("file", file);
                    try {
                      const res = await uploadApiService.uploadFile(
                        "https://api-qa.nft-square.io/api/public/resources/upload",
                        formData
                      );
                      return res?.fullUrl || "";
                    } catch (err) {
                    }
                  }}

                  listType="picture">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </DefUpload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="desc"
                rules={[{ required: false }]}
              >
                <ReactQuill
                  style={{ height: 500 }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video'
                  ]}
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                      [{ size: [] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' },
                      { 'indent': '-1' }, { 'indent': '+1' }],
                      ['link', 'image', 'video'],
                      ['clean']
                    ],
                    clipboard: {
                      // toggle to add extra line breaks when pasting HTML:
                      matchVisual: false,
                    }
                  }}
                  placeholder={"Desc"}
                  theme="snow"
                  value={form.getFieldValue("desc")}
                  onChange={(value, delta, source, editor) => {
                    form.setFieldValue("desc", value)
                  }} />;
              </Form.Item>
            </Col>
          </Row>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>

    </div>
  );
};

export default CreateEventView;
