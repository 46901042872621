import React, { Suspense } from 'react';
import "./index.less";
import { Drawer, Layout, Space, theme as antTheme } from 'antd';
import { Outlet } from 'react-router-dom';

import { useLayoutConfig } from '~/stores/layoutConfig';
import MainHeader from './MainHeader';
import MainMenu from './MainMenu';
import MainTabs from './MainTabs';

const { Sider, Content } = Layout;



const MainLayout = () => {
  const isMobile = false;
  const token = antTheme.useToken();
  const { setCollapsed, collapsed, menus, openKeys, selectedKey } = useLayoutConfig();

  const toggle = () => {
    setCollapsed(!collapsed)
  };
  return (
    <Layout className="layout-page">
      <MainHeader collapsed={collapsed} toggle={toggle} />
      <Layout>
        {!isMobile ? (
          <Sider
            className="layout-page-sider"
            trigger={null}
            collapsible
            style={{ backgroundColor: token.token.colorBgContainer }}
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="md"
          >
            <MainMenu
              menus={menus}
              openKeys={openKeys}
              selectedKey={selectedKey}
            />
          </Sider>
        ) : (
          <Drawer
            width="200"
            placement="left"
            bodyStyle={{ padding: 0, height: '100%' }}
            closable={false}
            onClose={toggle}
            open={!collapsed}
          >
            <MainMenu
              menus={menus}
              openKeys={openKeys}
              selectedKey={selectedKey}
            />
          </Drawer>
        )}
        <Content className="layout-page-content">
          <MainTabs />
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  )
}
export default MainLayout;
