import { IEnvConfig } from '..';

const config: IEnvConfig = {
  name: "PRODUCTION",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://app.moverse.biz/api/prod"
    },
  }
}

export default config;
