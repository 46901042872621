import { FC } from "react";
import { useNavigate, useRoutes } from "react-router";
import { RouteObject } from 'react-router';
import { navService, toastService } from "~/services";
import LoginView from "./auth/LoginView";
import { mainRouter } from "./main/main.router";
import MainLayout from "~/layouts/MainLayout";

const appRouter: RouteObject[] = [

  {
    path: "login",
    element: <LoginView />,
  },
  {
    path: '',
    element: <MainLayout />,
    children: mainRouter as RouteObject[],
  },
  // {
  //   path: '',
  //   element: <MasterLayout />,
  //   children: mainRouter
  // },

]
const AppRouter: FC = ({ ...props }) => {
  const navigate = useNavigate();
  navService.initRefNav(navigate);
  toastService.initNavigation(navigate);
  const element = useRoutes(appRouter);
  return element;
};

export default AppRouter;

