import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Spin, theme, Upload } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSearchParams } from 'react-router-dom';
import { EEventFormJoin, EEventMode, EEventType, ESessionSourceLink } from '~/common/enums';
import { rootApiService, toastService, uploadApiService } from '~/services';
import adminEventService from '~/services/admin-event.service';
import { v4 } from 'uuid';
import DefUpload from '~/components/DefUpload';
import dayjs from 'dayjs';
import { ApiException } from '~/@core/dto';

const { Option } = Select;

const makeFieldList = (image: string) => {
  if (!image) {
    return []
  }
  return [{
    uid: v4(),
    name: image,
    status: 'done',
    url: image
  }] as UploadFile<any>[]
}

const EditEventView: React.FC = () => {
  const { token: { colorBgBase, colorBgTextActive } } = theme.useToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [banner, setBanner] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [desc, setDesc] = useState("");
  const id = searchParams.get("id");

  const loadData = useCallback(async () => {
    try {
      if (!id) {
        throw new ApiException("Empty id")
      }
      const item = await adminEventService.detail({ id });
      const { banner, startTime, endTime, desc, ...resItem } = item;
      setBanner(banner);
      setDesc(desc);
      form.setFieldsValue({
        ...resItem,
        banner,
        desc,
        startTime: dayjs(startTime),
        endTime: dayjs(endTime),
        // startTime,
        // endTime,
      })
    } catch (error) {

    }
  }, [form, id])

  useEffect(() => {
    loadData();
  }, [form, id, loadData])


  const onFinish = async (values: any) => {
    const { startTime, endTime, ...restValues } = values;
    const body = {
      ...restValues,
      id,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString()
    }


    try {
      setIsLoading(true);
      await adminEventService.update(body);
      toastService.success();
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };




  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div style={{ width: '100%' }}>
      <Spin tip="Loading..." spinning={isLoading}  >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={[24, 2]}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="startTime"
                name="startTime"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <DatePicker showTime={{ format: "hh:mm" }} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="endTime"
                name="endTime"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <DatePicker
                  showTime={{ format: "hh:mm" }}
                  style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Mode"
                name="mode"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your Mode!' }]}
              >
                <Select

                  placeholder="Source Links Select"
                  allowClear

                >
                  <Option value={EEventMode.Public}>{EEventMode.Public}</Option>
                  <Option value={EEventMode.Private}>{EEventMode.Private}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Type"
                name="type"
                hasFeedback
                rules={[{ required: true, message: 'Please input your Type!' }]}
              >
                <Select
                  placeholder="Type Select"
                  allowClear

                >
                  <Option value={EEventType.Challenge}>{EEventType.Challenge}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="SourceLinks"
                name="acceptSourceLinks"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your SourceLinks!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Source Links Select"
                  allowClear

                >
                  <Option value={ESessionSourceLink.MOVERSE}>{ESessionSourceLink.MOVERSE}</Option>
                  <Option value={ESessionSourceLink.STRAVA}>{ESessionSourceLink.STRAVA}</Option>
                </Select>
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                label="Form join"
                name="formJoin"
                hasFeedback
                // validateStatus="success"
                rules={[{ required: true, message: 'Please input your formJoin!' }]}
              >
                <Select

                  placeholder="formJoin Select"
                  allowClear

                >
                  <Option value={EEventFormJoin.Personal}>{EEventFormJoin.Personal}</Option>
                  <Option value={EEventFormJoin.Team}>{EEventFormJoin.Team}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Organizer"
                name="organizer"
                rules={[{ required: true, message: 'Please input your organizer!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Bonus EMove"
                name="bonusEMove"
                rules={[{ required: true, message: 'Please input your bonusEMove!' }]}
              >
                <InputNumber style={{ width: '100%' }} min={1} />
              </Form.Item>
            </Col>


            <Col span={8}>
              <Form.Item
                name="banner"
                label="Upload banner"
                getValueProps={(value) => ({ value })}
              >
                <DefUpload
                  name="banner"
                  value={banner}
                  accept="image/*"
                  requestUpload={async (file) => {
                    const formData = new FormData();
                    formData.append("file", file);
                    try {
                      const res = await uploadApiService.uploadFile(
                        "https://api-qa.nft-square.io/api/public/resources/upload",
                        formData
                      );
                      return res?.fullUrl || "";
                    } catch (err) {
                    }
                  }}

                  listType="picture">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </DefUpload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="desc"
                rules={[{ required: false }]}
              >
                <ReactQuill

                  style={{ height: 500 }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video'
                  ]}
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                      [{ size: [] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' },
                      { 'indent': '-1' }, { 'indent': '+1' }],
                      ['link', 'image', 'video'],
                      ['clean']
                    ],
                    clipboard: {
                      // toggle to add extra line breaks when pasting HTML:
                      matchVisual: false,
                    }
                  }}


                  placeholder={"Desc"}
                  theme="snow"
                  value={desc}
                  onChange={(value, delta, source, editor) => {
                    console.log(`-------------------`);
                    console.log({
                      html1: editor.getContents(),
                      html2: editor.getHTML(),
                      html3: editor.getText()
                    });

                    console.log(`-------------------`);
                    setDesc(value);
                    form.setFieldValue("desc", value)
                  }} />;
              </Form.Item>
            </Col>
          </Row>
          <Form.Item >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>

    </div>
  );
};

export default EditEventView;
