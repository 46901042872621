import * as envs from './envs';

export interface IEnvConfig {
  name?: string,
  CONNECTORS: {
    ROOT: {
      baseUrl: string,
    }
  }
}
let envConfig: IEnvConfig = undefined;
export function configEnv(): IEnvConfig {
  if (envConfig) {
    return envConfig;
  }
  const envName = process.env.REACT_APP_ENV || 'dev';
  console.log(`Env: ${envName}`);
  const currentConfig = (envs)[envName];
  console.log({ currentConfig });
  return {
    ...currentConfig,
    name: envName
  }
};



