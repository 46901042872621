
import "./index.less";

const BaseView = ({ children }) => (
  <div className="base-view">
    {children}
  </div>
)

export default BaseView;
