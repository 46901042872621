
import React, { useState, FC } from 'react';
import { Button, Table, Image, Divider, theme, Tag, Col, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import TableControl from '~/@ui/TableControl';
import { EventDto } from '~/dto/events.dto';
import { rootApiService } from '~/services';
import { EditOutlined, DeleteOutlined, SearchOutlined, PlusCircleFilled, PlusOutlined, FieldNumberOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { EEventStatus, ESessionSourceLink } from '~/common/enums';
import tag from 'antd/es/tag';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { useLayoutConfig } from '~/stores/layoutConfig';
import { createSearchParams, useNavigate } from 'react-router-dom';
import adminEventService from '~/services/admin-event.service';



type IListEventViewProps = {
}

const ListEventView: FC<IListEventViewProps> = (props: IListEventViewProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const columns: ColumnsType<EventDto> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Banner',
      dataIndex: 'banner',
      key: 'banner',
      ellipsis: true,
      render: (value: string, record: EventDto, index: number) => {
        return <Image src={value} alt="banner" style={{ width: 30, height: 30 }} />
      }
    },
    {
      title: 'SourceLinks',
      key: 'acceptSourceLinks',
      dataIndex: 'acceptSourceLinks',
      ellipsis: true,
      render: (_, { acceptSourceLinks }) => (
        <>
          {acceptSourceLinks.map((sourceLink) => {
            const color = sourceLink === ESessionSourceLink.STRAVA ? '#fc5200' : colorPrimary;
            return (
              <Tag color={color} key={sourceLink}>
                {sourceLink.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Organizer',
      key: 'organizer',
      dataIndex: 'organizer',
      ellipsis: true,
    },
    {
      title: 'Mode',
      key: 'mode',
      dataIndex: 'mode',
      ellipsis: true,
    },

    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      ellipsis: true,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      ellipsis: true,
      render: (value: EEventStatus, record: EventDto, index: number) => {

        const colors = {
          [EEventStatus.Comming]: "default",
          [EEventStatus.InProgress]: "processing",
          [EEventStatus.Finish]: "success",
        }
        const indexOfValue = Object.values(EEventStatus).indexOf(value);
        const key = Object.keys(EEventStatus)[indexOfValue];
        return <Tag color={colors[value]}>{key}</Tag>
      }
    },

    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: 200,
      render: (value: any, record: any, index: number) => {
        const dateFormat = 'YYYY-MM-DD hh:mm';
        return <span>{moment(value, dateFormat).format('DD-MM-YYYY hh:mm')}</span>
      }
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      width: 200,
      render: (value: any, record: any, index: number) => {
        const dateFormat = 'YYYY-MM-DD hh:mm';
        return <span>{moment(value, dateFormat).format('DD-MM-YYYY hh:mm')}</span>
      }
    },
    {
      key: "action",
      title: "Action",
      render: (value: any, record: EventDto, index: number) => {
        return (
          <span>
            <Button type="primary" shape="circle" icon={<EditOutlined />}
              title={"edit"}
              onClick={() => navigate({
                pathname: "edit",
                search: createSearchParams({
                  id: record._id
                }).toString()
              })}
            />
            <Button type="primary" shape="circle" icon={<FieldNumberOutlined />}
              title={"buff"}
              onClick={() => navigate({
                pathname: "buff",
                search: createSearchParams({
                  id: record._id
                }).toString()
              })}
            />

            <Button type="primary" shape="circle" icon={<PlayCircleOutlined />}
              title={"reward"}
              onClick={() => navigate({
                pathname: "reward",
                search: createSearchParams({
                  id: record._id
                }).toString()
              })}
            />
            <Divider type="vertical" />
            <Button danger type="primary" shape="circle" icon={<DeleteOutlined />} title={"delete"}
            // onClick={handleDelete.bind(null, record)}
            />
          </span>
        )
      }
    }

  ];

  const loadData = ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    return adminEventService.getListEvent({
      pageIndex, pageSize
    })
  }
  return (
    <BaseView>
      <Row>
        <Col>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate({
              pathname: "create",
              // search: createSearchParams({
              //   id: "123"
              // }).toString()
            })}
          >
            ADD
          </Button>
        </Col>
        <Divider />
      </Row>
      <TableControl
        columns={columns}
        // dataSource={[]}
        // defaultPageSize={20}
        loadData={loadData}
      />
    </BaseView>
  )
}
export default ListEventView;
